import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent, FennecSnackbarService, Logger, MICaseService } from 'xf-common';
import { KeycloakService } from "keycloak-angular";
import { CaseHoldDialogComponent } from "@app/mi-case/case-hold-dialog/case-hold-dialog.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { FocusMonitor } from "@angular/cdk/a11y";
import {
  DuplicateEntityDialogComponent
} from "@app/duplicate-entity/duplicate-entity-dialog/duplicate-entity-dialog.component";
import { takeUntil } from "rxjs";


@Component({
  selector: 'app-mi-case',
  templateUrl: './mi-case.component.html',
  styleUrls: ['./mi-case.component.scss', '../../lib/styles/system-admin-styles.component.scss']
})
export class MICaseComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("MICaseComponent");

  // Used for routing navigation/selection from other components
  miCaseId: string | null; // miCaseId
  itemizedId: string | null;
  itemizedRevisionId: string | null;

  // An object that holds information on the current MI Case that's in play.
  miCaseInfo: any | null = null;

  userIsAdmin: boolean = false;
  caseUserView: boolean = false;
  userCanViewReportElementOverride: boolean = false;
  
  potentialRoutes: any[] = [
    { path: "overview", label: "Overview" },
    { path: "ub04", label: "UB04" },
    { path: "itemized", label: "Itemized" },
    { path: "attachments", label: "Attachments" },
    { path: "activity-diary", label: "Activity Diary"},
    { path: "task-list", label: "Tasks" },
    { path: "comments", label: "Comments" },
    { path: "finance", label: "Finance" },
    { path: "users", label: "Users", requirement: () => this.userIsAdmin || this.caseUserView },
    { path: "report-elements", label:"Report Elements", requirement: () => this.userCanViewReportElementOverride },
    { path: "appeal-level", label: "Appeal Lvls" },
  ];
  activeRoutes: any[] = [];
  
  constructor(
    protected snack: FennecSnackbarService,
    private miCaseService: MICaseService,
    private route: ActivatedRoute,
    private router: Router,
    private keycloakService: KeycloakService,
    protected matDialog: MatDialog,
    private _focusMonitor: FocusMonitor
  ) {
    super();
    this.miCaseId = this.route?.snapshot?.paramMap?.get('id');
    this.itemizedRevisionId = this.route?.snapshot?.paramMap?.get('itemizedRevisionId');
    this.itemizedId = this.route?.snapshot?.paramMap?.get('itemizedId');

    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.caseUserView = userRoles.includes("CASE_USER_READ");
    this.userCanViewReportElementOverride = userRoles.includes("MI_CASE_REPORT_ELEMENTS_READ") || this.userIsAdmin;

    this.buildRoutes();
    this.miCaseId = this.route.snapshot.paramMap.get("miCaseId") ?? null;

    // Set the tab group control to the desired selected index. We need to do this using a delay because
    // the component (with the tabGroup) is not available when this routing event is fired. We have to
    // wait a tiny amount of time for the component to render, then the code can find the tabGroup and
    // navigate to the right tab.
    setTimeout(() => {
      const showHoldInfo = history.state?.["HOLD_INFO_DIALOG"];
      if (showHoldInfo) {
        this.editHoldInfo();
      }
    }, 200);

  }

  ngOnInit() {}

  buildRoutes(): void {
    this.activeRoutes = this.potentialRoutes.filter(route => route.requirement == null || route.requirement());
  }

  getMiCaseInfo() {
    if (this.miCaseId == null || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Info",
      requestFn: this.miCaseService.getMICaseInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        if (response.data != null) {
          this.miCaseInfo = response.data;
          this.miCaseService.emitRefreshChildComponents();
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  ngAfterViewInit(): void {
    this.getMiCaseInfo();
    let holdChipElement = document.getElementById("hold-chip");
    if (holdChipElement != null) {
      this._focusMonitor.stopMonitoring(holdChipElement);
    }
    this.miCaseService.refreshCaseInfo$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => this.getMiCaseInfo());
  }

  editHoldInfo() {
    if (!this.miCaseId) {
      super.showErrorSnack("Please wait for the data to fully load. If the issue persists, please contact support.");
      return;
    }
    if (this.miCaseInfo?.terminalStatus) {
      super.showErrorSnack("Unable to modify case's ON HOLD status. Case is terminated.");
      return;
    }

    const dialogRef = this.matDialog.open(CaseHoldDialogComponent, { data: this.miCaseInfo });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.miCaseInfo = data;
      }
      this.miCaseService.emitRefreshCaseInfo();
    });
  }

  editDuplicateInfo() {
    if (!this.miCaseId) {
      super.showErrorSnack("Please wait for the data to fully load. If the issue persists, please contact support.");
      return;
    }

    const dialogRef = this.matDialog.open(DuplicateEntityDialogComponent, { data: { entityId: this.miCaseId, entityType: 'MI_CASE'}});

    dialogRef.afterClosed().subscribe(_data => {
      this.miCaseService.emitRefreshCaseInfo();
    });
  }
}
