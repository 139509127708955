<div *ngIf="itemizedRevisionList.length == 0" class="empty-itemized-revision-list-div">
  No Itemized Revisions currently available for the currently selected Itemized.
  <!-- No longer providing these options for Itemized Revisions. This is subject to change.
  <div class="create-itemized-revision-div">
    <button
      class="ir-action-button"
      mat-raised-button color="accent"
      (click)="createManualItemizedRevision()">
      Create Manual Itemized Revision
    </button>

    <button
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="createUploadItemizedRevision()">
      Create Itemized Revision via Upload
    </button>
  </div> -->
</div>

<div *ngIf="itemizedRevisionList.length > 0" class="itemized-revision-list-div">
  <mat-grid-list cols="{{itemizedRevisionList.length}}" rowHeight="fit" gutterSize="15px">
    <ng-container *ngFor="let ir of itemizedRevisionList">
      <mat-grid-tile>
        <mat-card class="mat-elevation-z8 itemized-revision-card" [ngStyle]="getSelectedStyle(ir.id)">
          <mat-card-header>
            <mat-card-title>Itemized Revision (Id: {{ir.id}})</mat-card-title>
            <mat-icon *ngIf="ir.reportMaster">summarize</mat-icon>
            <mat-card-subtitle>{{ir.name}}</mat-card-subtitle>
            <mat-card-subtitle><span class="irev-review-type">{{ir.itemizedRevisionReviewType}}</span></mat-card-subtitle>
            <mat-card-subtitle><span class="irev-created-date">Created {{ir.createdDate | date: "MM/dd/yyyy h:mm a"}}</span></mat-card-subtitle>
            <!-- Removing this for now as it messes with the card height. The icon suffices as a good
              indicator that this is the report revision itemized
            <mat-card-subtitle *ngIf="ir.reportMaster">
              Report Master Revision
            </mat-card-subtitle>
            -->
          </mat-card-header>
          <mat-card-content>
            <table style="width: 100%">
              <tr>
                <td>Origin: {{ir.itemizedRevisionOrigin}}</td>
                <td *ngIf="ir.apeProcessed"><img class="ape-processed-img" src="../assets/ape.png"></td>
                <td *ngIf="ir.info.appeal" style="text-align: center;">
                  <img class="ape-processed-img" src="../assets/appeal-icon.png">
                  <br>
                  <span>({{ir.info.appealLevels.length}})</span>
                </td>
                <td>
                  <table class="itemized-revision-summary-table">
                    <tr>
                      <td class="irev-summary-label-td">Line Items: </td>
                      <td class="itemized-revision-summary-number">{{ir.lineItemCount}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Total Bill Amt: </td>
                      <td class="itemized-revision-summary-number">{{ir.info.totalBilledAmount | currency}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Total Adj Amt: </td>
                      <td class="itemized-revision-summary-number">{{ir.info.totalAdjustedAmount | currency}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Savings Amt: </td>
                      <td [ngClass]="{'itemized-revision-summary-savings': (ir.info.totalBilledAmount - ir.info.totalAdjustedAmount) > 0, 'itemized-revision-summary-no-savings' : (ir.info.totalBilledAmount - ir.info.totalAdjustedAmount) == 0}">
                        {{ir.info.totalBilledAmount - ir.info.totalAdjustedAmount | currency}}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </mat-card-content>

          <mat-card-actions>
            <button mat-raised-button color="accent" (click)="selectItemizedRevision(ir)">Select</button>
          </mat-card-actions>    
        </mat-card>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>

  <!-- <div>
    <button
      mat-icon-button
      class="arrow-button"
      id="left" (click)="getItemizedRevisionViewList(currentIndex - itemizedRevisionViewAmount)"
      [disabled]="currentIndex == 0"><mat-icon>arrow_circle_left</mat-icon>
    </button>
  
    <p></p>

    <button
      mat-icon-button
      class="arrow-button"
      id="right" (click)="getItemizedRevisionViewList(currentIndex + itemizedRevisionViewAmount)"
      [disabled]="currentIndex + itemizedRevisionViewAmount >= itemizedRevisionList.length"><mat-icon>arrow_circle_right</mat-icon>
    </button>
  </div> -->

  <mat-paginator
    [pageSize]="defaultPageSize"
    [length]="totalRowCount"
    (page)="pageHandler()"
  ></mat-paginator>


  <div class="itemized-revision-actions" *ngIf="selectedItemizedRevision != null">
    <div style="margin-bottom: 10px;">
      <div>
        Selected:
        <span class="global-data-element-label-span">Id:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision.id}}</span>
        <span class="global-data-element-label-span"> Name:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision.name}}</span>
        <!-- Appeal Levels (multiple)-->
        <span style="margin-left: 3px; margin-right: 3px; z-index: 10" *ngFor="let al of selectedItemizedRevision.info.appealLevels">
          <img class="small-appeal-level-img" src="../assets/appeal-icon.png">
          {{al.appealLevelTypeLabel}}
        </span>
      </div>
      <div>
        Type: {{selectedItemizedRevision.itemizedRevisionReviewType}} 
        <button
          mat-icon-button
          (click)="openEditRevisionTypeDialog()"
          matTooltip="Edit Itemized Revision Type"
        ><mat-icon>edit</mat-icon></button>
      </div>
      <div>
        <span class="global-data-element-label-span">Billed:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision?.info.totalBilledAmount | currency}}</span>
        <span class="global-data-element-label-span"> Adj:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision?.info.totalAdjustedAmount | currency}}</span>
        <ng-container *ngIf="selectedItemizedRevision?.info.appealLevels.length > 0">
          <!-- Current calcs put findings equal to upheld - removing for now 
          <span class="global-data-element-label-span"> Findings:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision?.info.totalFindingsAmount | currency}}</span>
          -->
          <span class="global-data-element-label-span"> Overturned:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision?.info.totalOverturnedAmount | currency}}</span>
          <span class="global-data-element-label-span"> Upheld:</span> <span class="global-data-element-value-span">{{selectedItemizedRevision?.info.totalUpheldAmount | currency}}</span>
        </ng-container>
      </div>
    </div>

    <button class="ir-action-button" mat-raised-button color="accent" (click)="cloneItemizedRevision()">
      <mat-icon>content_copy</mat-icon> Clone Revision</button>
    <button *ngIf="!isSelectedRevisionAPEProcessed()" class="ir-action-button" mat-raised-button color="accent" (click)="sendToAPE()">
      <mat-icon>insights</mat-icon> Send to APE <img class="send-to-ape-img" src="../assets/ape.png">
    </button>
    <button class="ir-action-button" mat-raised-button color="accent" (click)="goToItemizedLineItems()">
      <mat-icon>list_alt</mat-icon>Access Itemized Line Items
    </button>

    <button
      *ngIf="canUserGenerateAppealReport && selectedItemizedRevision.itemizedRevisionReviewType == 'APPEAL'"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="generateAppealReport()">
      <mat-icon>note_add</mat-icon>Generate Appeal Report
    </button>    

    <button
      *ngIf="isSelectedRevisionReportMaster()"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="createProviderReportPDF()">
      <mat-icon>note_add</mat-icon>Generate Itemized Provider Report
    </button>

    <button
      *ngIf="isSelectedRevisionReportMaster()"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="createItemizedClientReportPDF()">
      <mat-icon>note_add</mat-icon>Generate Itemized Client Report
    </button>

    <button
      *ngIf="!isSelectedRevisionReportMaster()"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="designateReportMaster()">
      <mat-icon>summarize</mat-icon>Designate as Report Master
    </button>

    <!-- <span style="position:relative">
      <button
        class="ir-action-button"
        mat-raised-button
        color="accent"
        (click)="openCommentDialog()">
        <mat-icon>comments</mat-icon>View Comments
      </button>
    </span> -->

    <app-comment-list-dialog-button
      class="ir-action-button"
      [commentMode]="'ITEMIZED_REVISION'"
      [relatedId]="this.selectedItemizedRevisionId"
    ></app-comment-list-dialog-button>

    <button
      *ngIf="isUserAdmin || canUserExportRevision"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="exportToXLSX()"
    >
      <img 
        class="ms-excel-logo" 
        src="../../../assets/ms-excel-logo.png"
        />
        Generate XLSX File
    </button>

    <button
      *ngIf="canUserDeactivateRevisions || isUserAdmin"
      class="ir-action-button"
      mat-raised-button
      color="accent"
      (click)="deactivateItemizedRevision()">
      <mat-icon>delete</mat-icon>Deactivate
    </button>
  </div>

</div>
