
<!-- Note: use of [hidden] here is intentional. Child components need to stay alive for proper
  messaging.-->
  <div class="overview">
    <div>
      <mat-grid-list cols="{{appealLevelList.length + 1}}"
          rowHeight="200px" gutterSize="15px">
        <ng-container *ngFor="let alvl of appealLevelList">
          <mat-grid-tile>
            <mat-card class="mat-elevation-z8 appeal-level-card" [ngStyle]="getSelectedStyle(alvl.id)">
              <mat-card-header>
                <mat-card-title>Appeal Level (Id: {{ alvl.id }})</mat-card-title>
                <mat-card-subtitle>
                  <span class="global-data-element-value-span" style="font-size: large;">{{alvl.appealTypeLabel}}</span>
                  <br>
                  Level Type: {{ alvl.appealLevelTypeLabel }}
                  <br>
                  Created: {{ alvl.createdDate | date: "MM/dd/yyyy" }}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div *ngIf="alvl.itemizedRevisionId === null" 
                    style="color: darkred; font-style: italic;">
                  No Linked Itemized Revision
                </div>
                <div *ngIf="alvl.itemizedRevisionId !== null" 
                    style="color: green">
                  Itemized Revision Id: {{alvl.itemizedRevisionId}}
                </div>                
              </mat-card-content>
  
              <mat-card-actions>
                <button mat-raised-button color="accent" (click)="selectAppealLevel(alvl)">Select</button>
                <button 
                *ngIf="appealLevelList.length > 0 && alvl.id === appealLevelList[0].id && alvl.itemizedRevisionId === null"
                style="float: right;"
                mat-icon-button
                matTooltip="Delete"
                (click)="deleteAppealLevelPrompt(alvl)">
                <mat-icon>delete</mat-icon>
              </button> 
              </mat-card-actions> 
            </mat-card>
          </mat-grid-tile>
        </ng-container>
  
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-card class="mat-elevation-z8 title-center create-appeal-level-card">
              <mat-card-header>
                <mat-card-title>Create Appeal Level</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>
                  Create a new Appeal Level for <br>Case: {{ miCaseId }}
                </p>
              </mat-card-content>
  
              <mat-card-actions>
                <button mat-raised-button color="accent" (click)="createAppealLevel()">Create Appeal Level
                </button>
              </mat-card-actions>
            </mat-card>
          </mat-grid-tile>
  
      </mat-grid-list>
  
  </div>
  <mat-paginator
    #paginator
    class="lefty"
    [pageSize]="defaultPageSize"
    [length]="totalRowCount"
    (page)="getAppealLevelList()"
  >
  </mat-paginator>

  <div class="mi-case-appeal-level-actions" *ngIf="selectedAppealLevel !== null">
    <div>
      Selected:
      <span class="global-data-element-label-span">Id:</span> <span class="global-data-element-value-span">{{selectedAppealLevel?.id}}</span>
      <span class="global-data-element-label-span"> Type:</span> <span class="global-data-element-value-span">{{selectedAppealLevel?.appealTypeLabel}}</span>
      <span class="global-data-element-label-span"> Level Type:</span> <span class="global-data-element-value-span">{{selectedAppealLevel?.appealLevelTypeLabel}}</span>
      <br><br>  
      <div *ngIf="selectedAppealLevel?.itemizedRevisionId === null" 
        style="color: darkred; font-style: italic;">
        No Linked Itemized Revision
      </div>
      <div *ngIf="selectedAppealLevel?.itemizedRevisionId !== null">
        <span style="color: green">Itemized Revision Id: {{selectedAppealLevel?.itemizedRevisionId}}</span>
        <br><br>
        <span class="global-data-element-label-span">Billed:</span> <span class="global-data-element-value-span">{{itemizedRevisionInfo?.totalBilledAmount | currency}}</span>
        <span class="global-data-element-label-span"> Adj:</span> <span class="global-data-element-value-span">{{itemizedRevisionInfo?.totalAdjustedAmount | currency}}</span>
        <!-- Current calcs put findings equal to upheld - removing for now 
        <span class="global-data-element-label-span"> Findings:</span> <span class="global-data-element-value-span">{{itemizedRevisionInfo?.totalFindingsAmount | currency}}</span>
        -->
        <span class="global-data-element-label-span"> Overturned:</span> <span class="global-data-element-value-span">{{itemizedRevisionInfo?.totalOverturnedAmount | currency}}</span>
        <span class="global-data-element-label-span"> Upheld:</span> <span class="global-data-element-value-span">{{itemizedRevisionInfo?.totalUpheldAmount | currency}}</span>
      </div> 
      <br><br>
      <button mat-raised-button color="accent" (click)="selectItemizedRevision()">
        Select Itemized Revision
      </button>
      <button *ngIf="selectedAppealLevel?.itemizedRevisionId !== null" 
          mat-raised-button color="accent" style="margin-left: 3px"
          (click)="unlinkItemizedRevision()">
        Unlink Itemized Revision
      </button>
      <button *ngIf="selectedAppealLevel !== null" 
          mat-raised-button color="accent" style="margin-left: 3px"
          (click)="editAppealLevel()">
        Edit Appeal Level
      </button>      
    </div>

</div>


</div>
